<template>
	<div id="outbound-routes" class="view-content">
		<div class="page-heading">
			<h1>Outbound Routes</h1>
			<breadcrumb :items="breadcrumb_items" />
		</div>
		<div class="flex justify-content-between">
			<p-button icon="pi pi-plus" label="New Outbound Route" @click="$router.push({ path: '/outbound-routes/new' })" />
			<show-archived v-model="show_archived" />
		</div>
		<gutter size="20px" />
		<div class="card padded">
			<div id="outbound-routes-table">
				<p-data-table ref="dt" paginator :rows="50" scrollable :value="outbound_routes" :loading="loading">
					<template #loading>
						<line-loader :show="loading" />
					</template>
					<template #empty>
						<div class="dim">no results</div>
					</template>
					<p-column header="Outbound Route" sortable sort-field="name">
						<template #body="row">
							<router-link :to="{ path: `/outbound-routes/${row.data.id}` }">{{ row.data.name }}</router-link
							><br />
							<div class="sub-value">{{ row.data.description }}</div>
						</template>
					</p-column>
					<p-column header="ID" sortable sort-field="name">
						<template #body="row">
							{{ row.data.id }}
						</template>
					</p-column>
					<p-column header="Vertical" class="column-align-center">
						<template #body="row">
							<vertical-icon :vertical-id="row.data.vertical_id" size="30px" />
						</template>
					</p-column>
					<p-column header="Tag" sortable sort-field="provider_id">
						<template #body="row">
							{{ row.data.phone_number_tag }}
						</template>
					</p-column>
					<p-column header="Tools" class="align-center">
						<template #body="row">
							<div class="toolset">
								<div class="tool">
									<p-button
										v-tooltip.top="'Edit'"
										aria-label="Edit"
										@click="$router.push({ path: `/outbound-routes/${row.data.id}` })"
									>
										<template #icon>
											<icon type="square-edit-outline" size="20px" />
										</template>
									</p-button>
								</div>
							</div>
						</template>
					</p-column>
				</p-data-table>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import { capitalize, get } from 'lodash-es';
import { getOutboundRoutes } from '@GQL';
import verticalIcon from '@/components/elements/VerticalIcon.vue';
import showArchived from '@/components/widgets/ShowArchived.vue';
import pConfirmDialog from 'primevue/confirmdialog';

export default {
	name: 'PhoneNumbers',
	components: {
		pConfirmDialog,
		showArchived,
		verticalIcon,
	},
	data() {
		return {
			breadcrumb_items: [{ label: 'Phone Numbers' }],
			loading: false,
			show_archived: false,
			outbound_routes: [],
			filters: [],
		};
	},
	async mounted() {
		return await this.getSubAccounts();
	},
	methods: {
		capitalize,
		get,
		async getSubAccounts() {
			this.loading = true;
			try {
				this.outbound_routes = await getOutboundRoutes([]);
			} catch (e) {
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to get outbound routes',
				});
			} finally {
				this.loading = false;
			}
		},
	},
};
</script>
