<template>
	<div id="email-templates-list" class="view-content">
		<div class="page-heading">
			<h1>Email Templates</h1>
			<breadcrumb />
		</div>
		<div class="page-content">
			<row>
				<div class="flex w-full justify-content-between">
					<p-button label="New Email Template" @click="$router.push({ path: `${$route.path}/new` })">
						<template #icon>
							<div class="icon-wrapper button-icon">
								<icon type="plus" size="24px" />
							</div>
						</template>
					</p-button>
					<show-archived v-model="show_archived" />
				</div>
			</row>
			<gutter size="20px" />
			<row>
				<div class="column">
					<div class="card padded">
						<p-data-table
							ref="dt"
							scrollable
							:value="show_archived ? email_templates : activeEmailTemplates"
							:loading="loading"
						>
							<template #empty>
								<div class="dim">No Email Templates found</div>
							</template>
							<template #loading>
								<line-loader :show="loading" />
							</template>
							<p-column field="created_at" header="Created" sortable sortField="created_at">
								<template #body="row">
									{{ formatDate(row.data.created_at, 'ddd, MMM D, YYYY h:mm A') }}
								</template>
							</p-column>
							<p-column field="name" header="Template Name" sortable sortField="name">
								<template #body="row">
									<router-link :to="`${$route.path}/${row.data.id}`">{{ row.data.name }}</router-link>
								</template>
							</p-column>
							<p-column field="type" header="Subject" sortable sortField="subject">
								<template #body="row">
									<p class="sub-value">
										{{ row.data.subject }}
									</p>
								</template>
							</p-column>
							<p-column field="status" header="Status" sortable sortField="status">
								<template #body="row">
									{{ startCase(row.data.status) }}
								</template>
							</p-column>
							<p-column field="tags" header="Tags" sortable sortField="tags">
								<template #body="row">
									{{
										row.data.tags
											.map((item) => {
												return startCase(item);
											})
											.join(', ')
									}}
								</template>
							</p-column>
							<p-column header="Tools" class="column-align-center">
								<template #body="row">
									<div class="toolset">
										<div class="tool">
											<p-button
												v-tooltip.top="'Edit'"
												aria-label="Edit"
												@click="$router.push({ path: `${$route.path}/${row.data.id}` })"
											>
												<template #icon>
													<icon type="square-edit-outline" size="20px" />
												</template>
											</p-button>
										</div>
										<div class="tool">
											<p-button
												v-tooltip.top="'Duplicate Template'"
												aria-label="Duplicate"
												@click="duplicateTemplate(row.data.id)"
											>
												<template #icon>
													<icon type="content-copy" size="20px" />
												</template>
											</p-button>
										</div>
										<div v-if="row.data.status !== 'archived'" class="tool">
											<p-button
												v-tooltip.top="'Archive'"
												aria-label="Archive"
												@click="archiveEmailTemplate(row.data.id)"
											>
												<template #icon>
													<icon type="archive" size="20px" />
												</template>
											</p-button>
										</div>
										<div v-else class="tool">
											<p-button
												v-tooltip.top="'Activate'"
												aria-label="Activate"
												@click="activateEmailTemplate(row.data.id)"
											>
												<template #icon>
													<icon type="archive-off" size="20px" />
												</template>
											</p-button>
										</div>
										<div class="tool">
											<p-button v-tooltip.top="'Delete'" aria-label="Delete" @click="deleteTemplate(row.data.id)">
												<template #icon>
													<icon type="trash-can-outline" size="20px" />
												</template>
											</p-button>
										</div>
									</div>
								</template>
							</p-column>
						</p-data-table>
					</div>
				</div>
			</row>
		</div>
	</div>
</template>

<script lang="ts">
import showArchived from '@/components/widgets/ShowArchived.vue';
import { getEmailTemplates, updateEmailTemplate, deleteEmailTemplate } from '@GQL';
import { startCase } from 'lodash-es';
import { formatDate } from '@/lib/Filters';

export default {
	name: 'EmailTemplatesList',
	components: {
		showArchived,
	},
	data() {
		return {
			loading: false,
			email_templates: [],
			show_archived: false,
		};
	},
	async mounted() {
		await this.getEmailTemplates();
	},
	computed: {
		activeEmailTemplates() {
			return this.email_templates.filter((email_template) => email_template.status !== 'archived');
		},
	},
	methods: {
		startCase,
		formatDate,
		async activateEmailTemplate(id) {
			try {
				await updateEmailTemplate(id, {
					status: 'active',
				});
				this.$toast.add({
					severity: 'success',
					summary: 'Activated Email Template',
					life: 6000,
				});
				await this.getEmailTemplates(true);
			} catch (err) {
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to activate Email Template',
					detail: err.response.errors[0]?.message || err.message,
					life: 6000,
				});
			}
		},
		async archiveEmailTemplate(id) {
			try {
				await updateEmailTemplate(id, {
					status: 'archived',
				});
				this.$toast.add({
					severity: 'success',
					summary: 'Archived Email Template',
					life: 6000,
				});
				await this.getEmailTemplates(true);
			} catch (err) {
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to archive Email Template',
					detail: err.response.errors[0]?.message || err.message,
					life: 6000,
				});
			}
		},
		async getEmailTemplates(refresh?: boolean) {
			this.loading = true;
			try {
				const result = await getEmailTemplates([], refresh);
				this.email_templates = result;
			} catch (err) {
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to get Email Templates',
					detail: err.response.errors[0].message,
					life: 6000,
				});
			} finally {
				this.loading = false;
			}
		},
		duplicateTemplate(template_id) {
			this.$router.push({
				path: `${this.$route.path}/new`,
				query: {
					duplicate: template_id,
				},
			});
		},
		async deleteTemplate(id) {
			try {
				await deleteEmailTemplate(id);
				this.$toast.add({
					severity: 'success',
					summary: 'Deleted Email Template',
					life: 6000,
				});
				await this.getEmailTemplates(true);
			} catch (err) {
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to delete Email Template',
					detail: err.response.errors[0]?.message || err.message,
					life: 6000,
				});
			}
		},
	},
};
</script>
