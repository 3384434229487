<template>
	<div class="build-step reply-with-bids">
		<div class="title">
			<div class="move-handle">
				<icon type="dots-vertical" size="24px" />
			</div>
			<div class="name">Reply with LT Bids</div>
			<delete-action
				text
				rounded
				icon="close"
				message="Are you sure you want to remove this build step?"
				tooltip="Remove Build Step"
				@delete="removeBuildStep"
			/>
		</div>
		<div class="description">
			Return the bids that were collected while auctioning the LT lead. This step MUST be preceded by the "Send To
			Auctions" step.
		</div>
		<div class="step-settings">
			<h3>Settings</h3>
			<div class="control-group">
				<div class="inner">
					<label class="control-label">Bid Multiplier:</label>
					<div class="controls gap-20">
						<div class="field xs">
							<div class="p-inputgroup">
								<p-input-number v-model="modelValue.bid_multiplier" />
								<div class="p-inputgroup-addon">x</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import deleteAction from '@/components/widgets/DeleteAction.vue';

export default {
	name: 'ReplyWithLTBids',
	emits: ['remove'],
	components: {
		deleteAction,
	},
	props: {
		modelValue: {
			type: Object,
			default: {
				bid_multiplier: 1,
			},
		},
	},
	methods: {
		removeBuildStep() {
			this.$emit('remove');
		},
	},
};
</script>
