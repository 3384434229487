<template>
	<div id="live-transfer-start" class="view-content">
		<line-loader :show="loading" />
		<div class="flex align-items-center justify-content-between">
			<div class="page-heading">
				<h1>Live Transfer Workstation</h1>
				<div v-if="lead.vertical_id" class="lead-info">
					<vertical-icon :vertical-id="lead.vertical_id" size="30px" />
					{{ lead.id }}: {{ lead.first_name }} {{ lead.last_name }}
				</div>
			</div>
			<div>
				<div
					class="lead-details flex clickable lead-detail-button align-items-center"
					@click="show_lead_details = !show_lead_details"
				>
					<div class="phone">{{ phoneFormat(lead.phone) }}</div>
					<gutter size="15px" />
					<div class="location">{{ lead.city || 'Unknown City' }}, {{ lead.state }}</div>
					<gutter size="15px" />
					<div>
						<icon type="account" size="24px" />
					</div>
				</div>
				<div>
					<div class="lead-details flex">
						<div class="lead-details-dropdown fit">
							<div v-if="show_lead_details" class="lead-details-box">
								<!--                <div class="lead-tab clickable" @click="show_lead_details = !show_lead_details">-->
								<!--                  <icon type="close" size="24px"/>-->
								<!--                </div>-->
								<div class="lead-content">
									<div class="contact-info">
										<h3>Contact Info</h3>
										<ul class="unstyled table-list">
											<li>
												<span class="label">First Name:</span>
												<span class="value">{{ lead.first_name }}</span>
											</li>
											<li>
												<span class="label">Last Name:</span>
												<span class="value">{{ lead.last_name }}</span>
											</li>
											<li>
												<span class="label">Phone:</span>
												<span class="value">{{ phoneFormat(lead.phone) }}</span>
											</li>
											<li>
												<span class="label">Email:</span>
												<span class="value">{{ lead.email }}</span>
											</li>
											<li>
												<span class="label">Address:</span>
												<span class="value">{{ lead.street_1 }}</span>
											</li>
											<li>
												<span class="label">&nbsp;</span>
												<span class="value">{{ lead.city }}, {{ lead.state }} {{ lead.zip }}</span>
											</li>
										</ul>
									</div>
									<div class="vertical-info">
										<h3>Vertical Info</h3>
										<component :is="verticalDataComponent" :lead="lead" />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<gutter size="20px" />
		<div class="card vert-padded">
			<div class="questions">
				<div class="lt-script">
					<div class="lt-script-content">
						<div class="lt-script-icon">
							<icon type="comment-text-outline" size="24px" />
						</div>
						<div class="lt-script-body">
							<p>Now, I am going to ask you a couple of questions.</p>
						</div>
					</div>
				</div>
				<spoken-language :loaded="leadLoaded" :lead="lead" @update="updateLead" />
				<primary-covered :loaded="leadLoaded" />

				<div v-if="lead.vertical_id === 'health_insurance'">
					<affordability :lead="lead" @update="updateLead" />
					<preexisting-condition v-if="showPreexisingQuestion" :lead="lead" @update="updateLead" />
				</div>
				<div v-else-if="lead.vertical_id === 'medicare'">
					<enrollment :lead="lead" @update="updateLead" />
				</div>
				<location :lead="lead" @update="updateLead" />
			</div>

			<row class="justify-content-center">
				<p-button @click="runAuction" label="Run Auction" size="large">
					<template #icon>
						<div class="icon-wrapper button-icon">
							<icon type="gavel" size="28px" />
						</div>
					</template>
				</p-button>
			</row>
		</div>
	</div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core';
import { helpers } from '@vuelidate/validators';

import { cloneDeep, merge } from 'lodash-es';
import { getLiveTransferLead, updateLead } from '@GQL';
import verticalIcon from '@/components/elements/VerticalIcon.vue';
import spokenLanguage from '../Verticals/Common/SpokenLanguage.vue';
import affordability from '../Verticals/HealthInsurance/Affordability.vue';
import primaryCovered from '../Verticals/Common/PrimaryCovered.vue';
import preexistingCondition from '../Verticals/HealthInsurance/PreexistingCondition.vue';
import medicaid from '../Verticals/HealthInsurance/Medicaid.vue';
import location from '../Verticals/Common/Location.vue';
import enrollment from '../Verticals/Medicare/Enrollment.vue';
import log from '@/lib/Log';
import { phoneFormat } from '@/lib/Filters';
import HealthInsuranceDetails from '@/views/Leads/Verticals/HealthInsuranceDetails.vue';
import MedicareDetails from '@/views/Leads/Verticals/MedicareDetails.vue';

export default {
	name: 'LiveTransferForm',
	components: {
		affordability,
		enrollment,
		spokenLanguage,
		primaryCovered,
		preexistingCondition,
		medicaid,
		MedicareDetails,
		HealthInsuranceDetails,
		location,
		verticalIcon,
	},
	setup() {
		return {
			v$: useVuelidate(),
		};
	},
	data() {
		return {
			show_lead_details: false,
			lead: {},
			loading: false,
		};
	},
	computed: {
		ltErrors() {
			const health_validations = ['affordability'];
			if (this.showPreexisingQuestion) {
				health_validations.push('preexisting_condition');
			}
			const medicare_validations = ['has_medicare_parts_a_b'];
			const validations = this.lead.vertical_id === 'health_insurance' ? health_validations : medicare_validations;
			return this.v$.$errors.filter((error) => {
				return validations.includes(error.$propertyPath);
			});
		},
		leadLoaded() {
			return 'id' in this.lead;
		},
		verticalDataComponent() {
			if (this.lead.vertical_id === 'health_insurance') {
				return 'HealthInsuranceDetails';
			} else {
				return 'MedicareDetails';
			}
		},
		showPreexisingQuestion() {
			return ['WA'].indexOf(this.lead.state) === -1;
		},
	},
	async created() {
		try {
			this.loading = true;
			const result = await getLiveTransferLead(this.$route.params.lead_id, this.$route.params.vertical_id);
			this.lead = result;
		} catch (err) {
			await log.error('Loading Lead Error', 'error', { component: 'LTWorkstation/Form/Page', error: err });
		} finally {
			this.loading = false;
		}
	},
	methods: {
		phoneFormat,
		async runAuction() {
			const val = await this.v$.$validate();
			if (this.ltErrors.length == 0) {
				const lead_route = {
					name: 'LiveTransferResults',
					params: {
						vertical_id: this.lead.vertical_id,
						lead_id: this.lead.id,
					},
				};
				this.$router.push(lead_route);
			} else {
				this.$toast.add({
					severity: 'error',
					summary: 'Some fields are required to run the auction',
					life: 5000,
				});
			}
		},
		async updateLead(data) {
			if (!('id' in this.lead)) return;
			merge(this.lead, data);

			// Update the lead
			try {
				this.loading = true;
				const new_lead = cloneDeep(this.lead);
				delete new_lead.created_at;

				await updateLead(this.lead.id, new_lead);
				this.$toast.add({
					severity: 'success',
					summary: 'Lead updated',
					life: 3000,
				});
			} catch (err) {
				this.$toast.add({
					severity: 'error',
					summary: 'Update failed',
					detail: err.message,
					life: 3000,
				});
			} finally {
				this.loading = false;
			}
		},
	},
};
</script>

<style lang="less" scoped>
.lead-info {
	align-items: center;
	color: var(--gray-50);
	display: flex;
	font-weight: bold;
	gap: 10px;
}

.questions {
	padding: 30px;
}

:deep(label.radio-label) {
	font-weight: normal;
	margin-top: 3px;
}

.clickable {
	&:hover {
		cursor: pointer;
	}
}
.lead-details-box {
	//box-shadow: 2px 2px 3px 0 fade(var(--black), 15%);
	box-shadow: 2px 4px 6px 0 rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
	position: absolute;
	right: -350px;
	//top: 0;
	z-index: 200;

	.lead-tab {
		background-color: var(--white);
		border-radius: 3px 3px 0 0;
		border: 2px solid var(--gray-15);
		border-bottom: 0;
		padding: 0 10px;
		position: absolute;
		right: -2px;
		top: -2px;
	}

	.lead-content {
		background-color: var(--white);
		border-radius: 3px 0 3px 3px;
		box-shadow: 0 0 0 2px var(--gray-15);
		//margin-top: 44px;
		padding: 20px;
		width: 600px;

		h3 {
			//color: ;
			//font-family: @body-font;
			font-size: 1.125rem;
			font-weight: bold;
			margin-bottom: 0.5em;
			text-transform: uppercase;
		}

		.contact-info {
			margin-bottom: 2em;
		}

		.line-item {
			align-items: flex-start;
			color: var(--gray-65);
			font-size: 0.875rem;
			line-height: 1.5em;
			padding: 0.25em 0;

			.label {
				flex: 0 0 200px;
				font-weight: bold;
			}

			&.address {
				margin-top: 1em;
			}
		}
	}
}

.lead-detail-button {
	display: inline-block;
	padding: 10px 20px;
	font-size: 16px;
	font-weight: bold;
	//color: var(--white);
	background-color: var(--white);
	//background-color: #007bff; /* Button background color */
	border: 2px solid var(--gray-15); /* Border color */
	border-radius: 5px; /* Rounded corners */
	box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
	cursor: pointer; /* Pointer cursor to mimic button */
	text-align: center;
	transition:
		background-color 0.3s ease,
		box-shadow 0.3s ease;

	&:hover {
		//background-color: #0056b3; /* Darken the button on hover */
		box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15); /* Slightly increase shadow on hover */
	}
}

.lead-details-dropdown {
	border-left: 1px solid var(--gray-15);
	color: var(--gray-85);
	padding-left: 15px;
	position: relative;
}
.vert-padded {
	padding: 25px 0;
}
</style>
