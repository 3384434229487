<template>
	<div class="flex-transition-wrapper">
		<div id="phone-number-form" class="view-content">
			<page-loader :show="loading" />
			<div class="page-heading">
				<h1>{{ $route.params.route_template_id ? 'Edit' : 'Create' }} Route Template</h1>
				<breadcrumb :items="breadcrumb_items" />
			</div>
			<div class="form-content">
				<row>
					<column max-width="600px">
						<p-card>
							<template #content>
								<p class="req">Required Fields</p>
								<div class="control-group">
									<div class="inner">
										<label class="control-label req">Route Template Name:</label>
										<div class="controls">
											<div class="field">
												<p-input-text v-model="route_template.name" />
												<div v-if="v$.route_template.name.$error" class="validation-error">
													{{ v$.route_template.name.$errors[0].$message }}
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="control-group">
									<div class="inner">
										<label class="control-label req">Description:</label>
										<div class="controls">
											<div class="field">
												<p-input-text v-model="route_template.description" />
												<div v-if="v$.route_template.description.$error" class="validation-error">
													{{ v$.route_template.description.$errors[0].$message }}
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="control-group">
									<div class="inner">
										<label class="control-label req">Type:</label>
										<div class="controls">
											<div class="field">
												<p-dropdown
													v-model="route_template.type"
													:options="type_options"
													option-label="label"
													option-value="value"
												/>
											</div>
										</div>
									</div>
								</div>
								<div class="control-group">
									<div class="inner">
										<label class="control-label req">Route:</label>
										<div class="controls">
											<div v-if="route_template.type === 'voice'" class="field">
												<voice-route-builder v-model="route_template.route" />
											</div>
										</div>
									</div>
								</div>
								<div class="control-group">
									<div class="inner">
										<label class="control-label">Tags:</label>
										<div class="controls">
											<div class="field">
												<p-chips v-model="route_template.tags" separator="," add-on-blur />
											</div>
										</div>
									</div>
								</div>
							</template>
						</p-card>
					</column>
				</row>
			</div>
		</div>
		<div class="view-actions">
			<div class="flex justify-content-between align-items-center">
				<p-button severity="secondary" label="Cancel" text @click.prevent="$router.back()" />
				<p-button icon="pi pi-check" label="Save route template" @click.prevent="submit" />
			</div>
		</div>
	</div>
</template>
<script>
import { helpers, required } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import { cloneDeep, omit } from 'lodash-es';
import { getRouteTemplateById, insertRouteTemplate, updateRouteTemplate } from '@GQL';
import codeEditor from '@/components/forms/CodeEditor.vue';
import voiceRouteBuilder from '@/views/Calls/RouteTemplates/VoiceRoute/VoiceRouteBuilder.vue';
import pageLoader from '@/components/elements/Loader.vue';

const default_route_template = {
	name: null,
	description: null,
	type: 'voice',
	route: {
		default: [],
	},
	tags: [],
};

export default {
	name: 'RouteTemplateFormPage',
	components: {
		codeEditor,
		voiceRouteBuilder,
		pageLoader,
	},
	setup() {
		return {
			v$: useVuelidate(),
		};
	},
	data() {
		return {
			loading: false,
			route_template: cloneDeep(default_route_template),
			type_options: [
				{ label: 'Voice', value: 'voice' },
				{ label: 'SMS', value: 'sms' },
			],
			breadcrumb_items: [{ label: 'Route Templates', route: '/route-templates' }, { label: 'Edit Route Template' }],
		};
	},
	validations() {
		return {
			route_template: {
				name: {
					required: helpers.withMessage('A name is required', required),
				},
				description: {
					required: helpers.withMessage('A description is required', required),
				},
				type: {
					required: helpers.withMessage('A type is required', required),
				},
			},
		};
	},
	methods: {
		async submit() {
			const valid = await this.v$.$validate();
			if (valid) {
				this.loading = true;
				try {
					let result;
					if (this.$route.meta.new) {
						result = await insertRouteTemplate(this.route_template);
					} else {
						result = await updateRouteTemplate(this.route_template.id, omit(this.route_template, ['id', 'created_at']));
					}

					if (result) {
						this.$toast.add({
							severity: 'success',
							summary: `Successfully updated the route template`,
							detail: `${this.route_template.name}`,
							life: 5000,
						});
						// DO NAVIGATION
						this.$router.back();
					} else {
						this.$toast.add({
							severity: 'error',
							summary: `Unable to update the route template`,
							detail: `${this.route_template.name}`,
						});
					}
				} catch (err) {
					const msg_snippet = err.message.split(': {')[0];
					this.$toast.add({
						severity: 'error',
						summary: `Unable to update route template`,
						detail: `${msg_snippet}`,
					});
				} finally {
					this.loading = false;
				}
			} else {
				this.$toast.add({
					severity: 'error',
					summary: 'One or more fields are invalid',
					life: 5000,
				});
			}
		},
	},
	async mounted() {
		try {
			this.loading = true;
			if (!this.$route.meta.new) {
				const route_template = await getRouteTemplateById(this.$route.params.route_template_id);
				if (route_template) {
					this.route_template = cloneDeep(route_template);
				}
			}
		} catch (err) {
			this.$toast.add({
				severity: 'error',
				summary: 'Unable to properly initialize page',
				life: 6000,
			});
		} finally {
			this.loading = false;
		}
	},
};
</script>

<style lang="less" scoped>
.view-content {
	display: flex;
	flex-direction: column;
}

.form-content {
	flex: 1 0;
}

.type-option-box {
	border: 1px solid #ced4da;
	border-radius: 5px;
	padding: 1em;
	cursor: default;

	strong {
		display: block;
		font-size: 1.125em;
		margin-bottom: 5px;
	}

	&.selected {
		background-color: var(--color-b-lightest);
		border-color: var(--color-b-light);
		color: var(--color-b-light);

		strong {
			color: var(--color-b);
		}
	}
}

.detail-row {
	align-items: center;
	color: var(--gray-70);
	display: flex;
	flex-direction: row;
	font-size: 0.875rem;
	height: 2.5em;
}

.detail-label {
	flex: 0 0 200px;
}

.detail-value {
	flex: 1 0;
}

.detail-icon {
	display: inline-block;
	margin-right: 15px;
}
</style>
