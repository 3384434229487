<template>
	<div id="blocked-numbers" class="view-content">
		<div class="page-heading">
			<h1>Blocked Numbers</h1>
			<breadcrumb :items="breadcrumb_items" />
		</div>
		<div class="flex justify-content-between">
			<p-button icon="pi pi-plus" label="Add Blocked Number" @click="newBlockedNumber" />
		</div>
		<gutter size="20px" />
		<div class="card padded">
			<div id="route-templates-table">
				<p-data-table
					ref="dt"
					paginator
					:rows="50"
					scrollable
					sort-field="created_at"
					:sort-order="-1"
					:value="blocked_numbers"
					:loading="loading"
				>
					<template #loading>
						<line-loader :show="loading" />
					</template>
					<template #empty>
						<div class="dim">no results</div>
					</template>
					<p-column header="Blocked Number" sortable sort-field="phone">
						<template #body="row">
							{{ phoneFormat(row.data.phone) }}
						</template>
					</p-column>
					<p-column header="Reason" sortable sort-field="reason">
						<template #body="row">
							{{ row.data.reason }}
						</template>
					</p-column>
					<p-column header="User" sortable sort-field="user.first_name">
						<template #body="row">
							{{ fullName(row.data.user) }}
						</template>
					</p-column>
					<p-column header="Blocked On" sortable sort-field="created_at">
						<template #body="row">
							{{ dayjs(row.data.created_at).format('YYYY-MM-DD [at] h:mmA') }}<br />
							<span class="sub-value">{{ dayjs(row.data.created_at).fromNow() }}</span>
						</template>
					</p-column>
					<p-column header="Tools">
						<template #body="row">
							<delete-action
								:message="`Are you sure you want to unblock ${phoneFormat(row.data.phone)}?`"
								@delete="deleteBlockedNumber(row.data.phone)"
							/>
						</template>
					</p-column>
				</p-data-table>
			</div>
		</div>
		<p-dialog v-model:visible="show_blocked_number_modal" modal>
			<template #header>
				<div class="flex align-items-center gap-10">
					<icon type="phone-remove" size="20px" style="color: var(--red)" />
					<strong>Add Blocked Number</strong>
				</div>
			</template>
			<div class="control-group">
				<div class="inner">
					<label class="control-label req">Phone Number to Block:</label>
					<div class="controls">
						<div class="field">
							<p-input-mask v-model="blocked_number.phone" mask="(999) 999-9999" unmask />
							<div v-if="v$.blocked_number.phone.$error" class="validation-error">
								{{ v$.blocked_number.phone.$errors[0].$message }}
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="control-group">
				<div class="inner">
					<label class="control-label req">Reason:</label>
					<div class="controls">
						<div class="field">
							<p-dropdown
								v-model="blocked_number.reason"
								:options="reason_options"
								option-label="label"
								option-value="value"
							/>
							<div v-if="v$.blocked_number.reason.$error" class="validation-error">
								{{ v$.blocked_number.reason.$errors[0].$message }}
							</div>
						</div>
					</div>
				</div>
			</div>
			<template #footer>
				<div class="flex align-items-center justify-content-between">
					<p-button text label="cancel" @click="show_blocked_number_modal = false" />
					<p-button label="Add Blocked Number" @click="insertBlockedNumber" />
				</div>
			</template>
		</p-dialog>
	</div>
</template>

<script lang="ts">
import { cloneDeep } from 'lodash-es';
import { phoneFormat } from '@/lib/Filters';
import { deleteBlockedNumber, getBlockedNumbers, insertBlockedNumber } from '@GQL';
import deleteAction from '@/components/widgets/DeleteAction.vue';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { helpers, required } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';

dayjs.extend(relativeTime);

const default_blocked_number = {
	phone: null,
	reason: null,
	user_id: null,
};

export default {
	name: 'BlockedNumbers',
	components: {
		deleteAction,
	},
	setup() {
		return {
			v$: useVuelidate(),
		};
	},
	data() {
		return {
			breadcrumb_items: [{ label: 'Blocked Numbers' }],
			loading: false,
			show_archived: false,
			blocked_number: cloneDeep(default_blocked_number),
			blocked_numbers: [],
			filters: [],
			show_blocked_number_modal: false,
			reason_options: [{ label: 'Litigator', value: 'Litigator' }],
		};
	},
	validations() {
		return {
			blocked_number: {
				phone: {
					required: helpers.withMessage('A phone number is required', required),
					unique: helpers.withMessage('This phone number is already blocked', (value) => {
						const existing_phone = this.blocked_numbers.find((blocked_number) => {
							return blocked_number.phone === value;
						});

						if (existing_phone) {
							return false;
						}

						return true;
					}),
				},
				reason: {
					required: helpers.withMessage('A reason is required', required),
				},
			},
		};
	},
	async mounted() {
		await this.getBlockedNumbers();
	},
	methods: {
		phoneFormat,
		fullName(user) {
			return `${user.first_name} ${user.last_name}`;
		},
		dayjs,
		async getBlockedNumbers() {
			this.loading = true;
			try {
				this.blocked_numbers = await getBlockedNumbers(this.filters);
			} catch (err) {
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to get blocked numbers',
					life: 5000,
				});
			} finally {
				this.loading = false;
			}
		},
		newBlockedNumber() {
			this.blocked_number = cloneDeep(default_blocked_number);
			this.show_blocked_number_modal = true;
		},
		async deleteBlockedNumber(phone) {
			this.loading = true;
			try {
				await deleteBlockedNumber(phone);
				this.blocked_numbers = await getBlockedNumbers(this.filters);

				this.$toast.add({
					severity: 'success',
					summary: 'The number was deleted from the block list',
					life: 3000,
				});
			} catch (err) {
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to delete the blocked number',
					life: 5000,
				});
			} finally {
				this.loading = false;
			}
		},
		async insertBlockedNumber() {
			const is_valid = await this.v$.$validate();

			if (is_valid) {
				try {
					this.blocked_number.user_id = this.$root.sessionStore.user.id;
					const result = await insertBlockedNumber(this.blocked_number);

					this.$toast.add({
						severity: 'success',
						summary: 'Phone Number Blocked',
						detail: `${phoneFormat(result.phone)} was added to the block list`,
						life: 3000,
					});

					await this.getBlockedNumbers();
					this.show_blocked_number_modal = false;
				} catch (err) {
					this.$toast.add({
						severity: 'error',
						summary: 'Unable to Block Number',
						detail: `${phoneFormat(result.phone)} was not added to the block list`,
						life: 5000,
					});
				}
			}
		},
	},
};
</script>
