<template>
	<div id="phone-numbers" class="view-content">
		<div class="page-heading">
			<h1>Phone Numbers</h1>
			<breadcrumb :items="breadcrumb_items" />
		</div>
		<div class="flex justify-content-between">
			<p-button icon="pi pi-plus" label="New Phone Number" @click="openNewPhoneNumberModal()" />
			<show-archived v-model="show_archived" />
		</div>
		<gutter size="20px" />
		<div class="card padded">
			<div id="phone-numbers-table">
				<p-data-table
					ref="dt"
					paginator
					:rows="50"
					scrollable
					:value="activePhoneNumbers"
					v-model:filters="filters"
					:global-filter-fields="['name', 'description', 'phone', 'provider_account.name']"
					:loading="loading"
					:row-class="(data) => data.status"
					data-key="id"
				>
					<template #header>
						<div class="flex justify-content-between">
							<p-input-text
								v-model="filters['global'].value"
								style="width: 100%; max-width: 400px"
								placeholder="Search Phone Numbers..."
							/>
						</div>
					</template>
					<template #loading>
						<line-loader :show="loading" />
					</template>
					<template #empty>
						<div class="dim">no results</div>
					</template>
					<p-column header="Marketplace" sortable sort-field="mpid" class="align-center" style="width: 5em">
						<template #body="row">
							{{ row.data.mpid === '$NG' ? 'Global' : row.data.mpid }}
						</template>
					</p-column>
					<p-column header="Vertical" sortable sort-field="vertical_id" class="align-center" style="width: 5em">
						<template #body="row">
							<vertical-icon :vertical-id="row.data.vertical_id" size="30px" />
						</template>
					</p-column>
					<p-column header="Description" sortable sort-field="description">
						<template #body="row">
							<router-link :to="`/phone-numbers/${row.data.id}`"> {{ row.data.description || 'None' }} </router-link
							><br />
							<div class="sub-value">{{ row.data.provider_phone_id }}</div>
						</template>
					</p-column>
					<p-column header="Number" sortable sort-field="phone" class="align-center">
						<template #body="row">
							{{ phoneFormat(row.data.phone) }}
						</template>
					</p-column>
					<p-column header="Type" sortable sort-field="type" class="align-center">
						<template #body="row">
							{{ capitalize(row.data.type) }}
						</template>
					</p-column>
					<p-column header="Account" sortable sort-field="provider_account.name">
						<template #body="row">
							<router-link :to="`/sub-accounts/${get(row.data, 'provider_account.id')}`">{{
								get(row.data, 'provider_account.name', 'Unknown')
							}}</router-link
							><br />
							<div class="sub-value">{{ get(row.data, 'provider_account.id', 'NA') }}</div>
						</template>
					</p-column>
					<p-column header="Status" sortable sort-field="status" class="align-center">
						<template #body="row">
							{{ capitalize(row.data.status || 'Unknown') }}
						</template>
					</p-column>
					<p-column header="Tools" class="align-center">
						<template #body="row">
							<div class="toolset">
								<div class="tool">
									<p-button
										v-tooltip.top="'Edit'"
										aria-label="Edit"
										@click="$router.push({ path: `/phone-numbers/${row.data.id}` })"
									>
										<template #icon>
											<icon type="square-edit-outline" size="20px" />
										</template>
									</p-button>
								</div>
								<div class="tool">
									<p-button
										v-tooltip.top="'Transfer Accounts'"
										aria-label="Transfer"
										@click.prevent="transferPhoneNumber(row.data)"
									>
										<template #icon>
											<icon type="swap-horizontal" size="20px" />
										</template>
									</p-button>
								</div>
								<div class="tool">
									<p-button
										v-tooltip.top="row.data.status === 'active' ? 'Archive' : 'Unarchive'"
										aria-label="Archive"
										@click.prevent="handleArchive(row.data)"
									>
										<template #icon>
											<icon v-if="row.data.status === 'active'" type="archive" size="20px" />
											<icon v-else type="archive-off" size="20px" />
										</template>
									</p-button>
								</div>
							</div>
						</template>
					</p-column>
				</p-data-table>
			</div>
		</div>
		<purchase-phone-number-modal ref="new_phone_number_modal" />
		<transfer-phone-number-modal ref="transfer_phone_number_modal" @updateRow="updatePhoneRow" />
		<p-confirm-popup :group="confirm_id" />
	</div>
</template>

<script lang="ts">
import { capitalize, get, uniqueId } from 'lodash-es';
import { getPhoneNumbers, getSubAccountsAsOptions, updatePhoneNumber } from '@GQL';
import { phoneFormat } from '@/lib/Filters';
import verticalIcon from '@/components/elements/VerticalIcon.vue';
import showArchived from '@/components/widgets/ShowArchived.vue';
import purchasePhoneNumberModal from '@/views/Calls/PhoneNumbers/Modals/PurchasePhone.vue';
import transferPhoneNumberModal from '@/views/Calls/PhoneNumbers/Modals/TransferPhone.vue';
import pMultiSelect from 'primevue/multiselect';
import pConfirmPopup from 'primevue/confirmpopup';
import { FilterMatchMode, FilterOperator } from 'primevue/api';
import log from '@/lib/Log';

export default {
	name: 'PhoneNumbers',
	components: {
		pConfirmPopup,
		pMultiSelect,
		showArchived,
		verticalIcon,
		purchasePhoneNumberModal,
		transferPhoneNumberModal,
	},
	data() {
		return {
			breadcrumb_items: [{ label: 'Phone Numbers' }],
			loading: false,
			show_archived: false,
			phone_numbers: [],
			filters: {
				global: { value: null, matchMode: FilterMatchMode.CONTAINS },
				name: { value: null, matchMode: FilterMatchMode.CONTAINS },
			},
			sub_account_options: [],
			confirm_id: uniqueId(),
		};
	},
	computed: {
		activePhoneNumbers() {
			if (this.show_archived) {
				return this.phone_numbers;
			}
			return this.phone_numbers.filter((phone_number) => phone_number.status !== 'archived');
		},
	},
	async mounted() {
		this.sub_account_options = await getSubAccountsAsOptions();
		await this.getPhoneNumbers();
	},
	methods: {
		capitalize,
		get,
		phoneFormat,
		async getPhoneNumbers() {
			this.loading = true;
			try {
				this.phone_numbers = await getPhoneNumbers();
			} catch (e) {
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to get phone numbers',
				});
			} finally {
				this.loading = false;
			}
		},
		openNewPhoneNumberModal() {
			this.$refs.new_phone_number_modal.open();
		},
		transferPhoneNumber(phone) {
			this.$refs.transfer_phone_number_modal.open(phone);
		},
		updatePhoneRow(data) {
			const phone_row = this.phone_numbers.find((row) => {
				return row.id === data.id;
			});

			phone_row.provider_account = data.provider_account;
		},
		async handleArchive(phone_number) {
			if (phone_number.status === 'archived') {
				try {
					await updatePhoneNumber(phone_number.id, { status: 'active' });
					const matching_phone_number = this.phone_numbers.find((local_phone_number) => {
						return local_phone_number.id === phone_number.id;
					});
					if (matching_phone_number) {
						matching_phone_number.status = 'active';
					}
					this.$toast.add({
						severity: 'success',
						summary: 'Unarchived Phone',
						detail: `The phone number was unarchived: ${phoneFormat(phone_number.phone)}`,
						life: 3000,
					});
				} catch (err) {
					log.trace(err);
					this.$toast.add({
						severity: 'error',
						summary: 'Unable to unarchive phone number',
						life: 3000,
					});
				}
			} else {
				this.$confirm.require({
					header: 'Archive Phone Number',
					group: this.confirm_id,
					message: 'Are you sure you want to archive this Phone Number?',
					icon: 'pi pi-exclamation-triangle',
					acceptClass: 'p-button-danger',
					accept: async () => {
						try {
							await updatePhoneNumber(phone_number.id, { status: 'archived' });
							const matching_phone_number = this.phone_numbers.find((local_phone_number) => {
								return local_phone_number.id === phone_number.id;
							});
							if (matching_phone_number) {
								matching_phone_number.status = 'archived';
							}
							this.$toast.add({
								severity: 'success',
								summary: 'Archived',
								detail: `Archived Phone Number: ${phoneFormat(phone_number.phone)}`,
								life: 3000,
							});
						} catch (err) {
							log.trace(err);
							this.$toast.add({
								severity: 'error',
								summary: 'Unable to archive Phone Number',
								life: 3000,
							});
						}
					},
				});
			}
		},
	},
};
</script>

<style lang="less" scoped>
.disabled {
	color: var(--gray-50) !important;
}

:deep(.p-datatable-header) {
	background-color: white;
	padding: 0 0 10px;
}

:deep(tr.archived) {
	font-style: italic;
	opacity: 0.65;
}
</style>
