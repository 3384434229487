<template>
	<div id="route-templates" class="view-content">
		<div class="page-heading">
			<h1>Route Templates</h1>
			<breadcrumb :items="breadcrumb_items" />
		</div>
		<div class="flex justify-content-between">
			<p-button icon="pi pi-plus" label="New Route Template" @click="$router.push({ path: '/route-templates/new' })" />
		</div>
		<gutter size="20px" />
		<div class="card padded">
			<div id="route-templates-table">
				<p-data-table ref="dt" paginator :rows="50" scrollable :value="route_templates" :loading="loading">
					<template #loading>
						<line-loader :show="loading" />
					</template>
					<template #empty>
						<div class="dim">no results</div>
					</template>
					<p-column header="Route Template" sortable sort-field="name">
						<template #body="row">
							<router-link :to="{ path: `/route-templates/${row.data.id}` }">{{ row.data.name }}</router-link
							><br />
							<div class="sub-value">{{ row.data.description }}</div>
						</template>
					</p-column>
					<p-column header="Route ID" sortable sort-field="id">
						<template #body="row">
							{{ row.data.id }}
						</template>
					</p-column>
					<p-column header="Type" sortable sort-field="type">
						<template #body="row"> {{ capitalize(row.data.type) }}</template>
					</p-column>
					<p-column header="Tools" class="align-center">
						<template #body="row">
							<div class="toolset">
								<div class="tool">
									<p-button
										v-tooltip.top="'Edit'"
										aria-label="Edit"
										@click="$router.push({ path: `/route-templates/${row.data.id}` })"
									>
										<template #icon>
											<icon type="square-edit-outline" size="20px" />
										</template>
									</p-button>
								</div>
								<delete-action
									message="Are you sure you want to delete this route template?"
									@delete="deleteRouteTemplate(row.data.id)"
								/>
							</div>
						</template>
					</p-column>
				</p-data-table>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import { capitalize, get } from 'lodash-es';
import { getRouteTemplates, deleteRouteTemplate } from '@GQL';
import verticalIcon from '@/components/elements/VerticalIcon.vue';
import pConfirmPopup from 'primevue/confirmpopup';
import deleteAction from '@/components/widgets/DeleteAction.vue';

export default {
	name: 'PhoneNumbers',
	components: {
		deleteAction,
		pConfirmPopup,
		verticalIcon,
	},
	data() {
		return {
			breadcrumb_items: [{ label: 'Route Templates' }],
			loading: false,
			show_archived: false,
			route_templates: [],
			filters: [],
		};
	},
	async mounted() {
		return await this.getRouteTemplates();
	},
	methods: {
		capitalize,
		get,
		async getRouteTemplates() {
			this.loading = true;
			try {
				this.route_templates = await getRouteTemplates([]);
			} catch (e) {
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to get route templates',
				});
			} finally {
				this.loading = false;
			}
		},
		async deleteRouteTemplate(route_template_id) {
			try {
				await deleteRouteTemplate(route_template_id);
				this.$toast.add({
					severity: 'success',
					summary: 'Route Template Deleted',
					detail: 'The route template was successfully deleted',
					life: 3000,
				});
				await this.getRouteTemplates();
			} catch (err) {
				this.$toast.add({
					severity: 'error',
					summary: 'Error Deleting Route Template',
					detail: 'Unable to delete the route template',
					life: 5000,
				});
			}
		},
	},
};
</script>
