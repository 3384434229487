<template>
	<div id="phone-number-form" class="view-content">
		<page-loader :show="loading" />
		<div class="page-heading">
			<h1>{{ $route.params.outbound_route_id ? 'Edit' : 'Create' }} Outbound Route</h1>
			<breadcrumb :items="breadcrumb_items" />
		</div>
		<div class="form-content">
			<row>
				<column max-width="760px">
					<p-card>
						<template #content>
							<p class="req">Required Fields</p>
							<div class="control-group">
								<div class="inner">
									<label class="control-label req">Outbound Route ID:</label>
									<div class="controls">
										<div class="field">
											<p-input-text v-model="outboundRouteId" :disabled="!$route.meta.new" />
											<div v-if="v$.outbound_route.id.$error" class="validation-error">
												{{ v$.outbound_route.id.$errors[0].$message }}
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="control-group">
								<div class="inner">
									<label class="control-label req">Outbound Route Name:</label>
									<div class="controls">
										<div class="field">
											<p-input-text v-model="outbound_route.name" />
											<div v-if="v$.outbound_route.name.$error" class="validation-error">
												{{ v$.outbound_route.name.$errors[0].$message }}
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="control-group">
								<div class="inner">
									<label class="control-label">Description:</label>
									<div class="controls">
										<div class="field">
											<p-input-text v-model="outbound_route.description" />
										</div>
									</div>
								</div>
							</div>
							<div class="control-group">
								<div class="inner">
									<label class="control-label req">Vertical:</label>
									<div class="controls">
										<div class="field">
											<p-dropdown
												v-model="outbound_route.vertical_id"
												:options="$root.appStore.options.verticals"
												option-label="label"
												option-value="value"
												placeholder="Select Vertical"
											/>
											<div v-if="v$.outbound_route.vertical_id.$error" class="validation-error">
												{{ v$.outbound_route.vertical_id.$errors[0].$message }}
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="control-group">
								<div class="inner">
									<label class="control-label req"
										>Phone Number Tag:
										<icon
											v-tooltip.top="'Phone numbers with this tag will be used for outbound dialing'"
											type="information-outline"
											size="16px"
									/></label>
									<div class="controls">
										<div class="field">
											<p-input-text v-model="outbound_route.phone_number_tag" />
											<div v-if="v$.outbound_route.phone_number_tag.$error" class="validation-error">
												{{ v$.outbound_route.phone_number_tag.$errors[0].$message }}
											</div>
										</div>
									</div>
								</div>
							</div>
							<p-fieldset legend="Route">
								<p>
									Select a route template or set up custom logic here. If no route templates are available, try setting
									the vertical for the phone number first.
									<strong>The route will begin after the call is connected to the outbound number being dialed.</strong>
								</p>
								<div class="control-group">
									<div class="inner">
										<label class="control-label">Route Template:</label>
										<div class="controls">
											<div class="field">
												<p-dropdown
													v-model="outbound_route.route_template_id"
													:options="voiceRouteOptions"
													option-label="label"
													option-value="value"
													placeholder="Set Custom Logic Below"
												/>
											</div>
										</div>
									</div>
								</div>
								<div v-if="!outbound_route.route_template_id" class="control-group">
									<div class="inner">
										<label class="control-label">Route:</label>
										<div class="controls">
											<div class="field">
												<voice-route-builder v-model="outbound_route.route" :allow-add-sub-route="false" />
											</div>
										</div>
									</div>
								</div>
							</p-fieldset>
							<gutter size="1rem" />
							<div class="control-group">
								<div class="inner">
									<label class="control-label">Route Data:</label>
									<div class="controls">
										<div class="field">
											<code-editor v-model.parse="outbound_route.route_data" />
										</div>
									</div>
								</div>
							</div>
							<div class="form-actions">
								<p-button severity="secondary" label="Cancel" text @click.prevent="$router.back()" />
								<p-button icon="pi pi-check" label="Save Outbound Route" @click.prevent="submit" />
							</div>
						</template>
					</p-card>
				</column>
			</row>
		</div>
	</div>
</template>
<script>
import { helpers, required } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import { cloneDeep, snakeCase, omit } from 'lodash-es';
import { computedAsync } from '@vueuse/core';
import { getOutboundRouteById, getRouteTemplates, insertOutboundRoute, updateOutboundRoute } from '@GQL';
import codeEditor from '@/components/forms/CodeEditor.vue';
import voiceRouteBuilder from '@/views/Calls/RouteTemplates/VoiceRoute/VoiceRouteBuilder.vue';
import pageLoader from '@/components/elements/Loader.vue';

const default_outbound_route = {
	name: null,
	description: null,
	vertical_id: null,
	phone_number_tag: null,
	route_template_id: null,
	route: {
		default: [],
	},
	route_data: {},
};

export default {
	name: 'PhoneNumberFormPage',
	components: {
		codeEditor,
		voiceRouteBuilder,
		pageLoader,
	},
	setup() {
		const voiceRouteOptions = computedAsync(async () => {
			const route_templates = await getRouteTemplates();
			let route_template_options = [];
			if (route_templates) {
				route_template_options = route_templates.map((template) => {
					return {
						label: template.name,
						value: template.id,
					};
				});
			}
			return [{ label: 'Custom Logic', value: null }, ...route_template_options];
		});

		return {
			voiceRouteOptions,
			v$: useVuelidate(),
		};
	},
	data() {
		return {
			loading: false,
			outbound_route: cloneDeep(default_outbound_route),
			provider_options: [],
			breadcrumb_items: [{ label: 'Sub-Accounts', route: '/sub-accounts' }, { label: 'Edit Sub-Account' }],
		};
	},
	computed: {
		outboundRouteId: {
			get() {
				return this.outbound_route.id;
			},
			set(new_value) {
				this.outbound_route.id = snakeCase(new_value);
			},
		},
	},
	watch: {
		'outbound_route.name': {
			handler(new_value) {
				if (this.$route.meta.new) {
					this.outboundRouteId = new_value;
				}
			},
		},
	},
	validations() {
		return {
			outbound_route: {
				id: {
					required: helpers.withMessage('An ID is required.', required),
				},
				name: {
					required: helpers.withMessage('A name is required.', required),
				},
				vertical_id: {
					required: helpers.withMessage('A vertical is required.', required),
				},
				phone_number_tag: {
					required: helpers.withMessage('A phone number tag is required.', required),
				},
			},
		};
	},
	methods: {
		async submit() {
			const valid = await this.v$.$validate();
			if (valid) {
				this.loading = true;
				try {
					let result;
					if (this.$route.meta.new) {
						result = await insertOutboundRoute(this.outbound_route);
					} else {
						result = await updateOutboundRoute(this.outbound_route.id, omit(this.outbound_route, ['id', 'created_at']));
					}

					if (result) {
						this.$toast.add({
							severity: 'success',
							summary: `Successfully inserted the outbound route`,
							detail: `${this.outbound_route.name}`,
							life: 5000,
						});
						// DO NAVIGATION
						this.$router.back();
					} else {
						this.$toast.add({
							severity: 'error',
							summary: `Unable to update the outbound route`,
							detail: `${this.outbound_route.name}`,
						});
					}
				} catch (err) {
					const msg_snippet = err.message.split(': {')[0];
					this.$toast.add({
						severity: 'error',
						summary: `Unable to update outbound route`,
						detail: `${msg_snippet}`,
					});
				} finally {
					this.loading = false;
				}
			} else {
				this.$toast.add({
					severity: 'error',
					summary: 'One or more fields are invalid',
					life: 5000,
				});
			}
		},
	},
	async mounted() {
		try {
			this.loading = true;
			if (!this.$route.meta.new) {
				const outbound_route = await getOutboundRouteById(this.$route.params.outbound_route_id);
				if (outbound_route) {
					this.outbound_route = cloneDeep(outbound_route);
				}
			}
		} catch (err) {
			this.$toast.add({
				severity: 'error',
				summary: 'Unable to properly initialize page',
				life: 6000,
			});
		} finally {
			this.loading = false;
		}
	},
};
</script>

<style lang="less" scoped>
.view-content {
	display: flex;
	flex-direction: column;
}

.form-content {
	flex: 1 0;
}

.type-option-box {
	border: 1px solid #ced4da;
	border-radius: 5px;
	padding: 1em;
	cursor: default;

	strong {
		display: block;
		font-size: 1.125em;
		margin-bottom: 5px;
	}

	&.selected {
		background-color: var(--color-b-lightest);
		border-color: var(--color-b-light);
		color: var(--color-b-light);

		strong {
			color: var(--color-b);
		}
	}
}

.detail-row {
	align-items: center;
	color: var(--gray-70);
	display: flex;
	flex-direction: row;
	font-size: 0.875rem;
	height: 2.5em;
}

.detail-label {
	flex: 0 0 200px;
}

.detail-value {
	flex: 1 0;
}

.detail-icon {
	display: inline-block;
	margin-right: 15px;
}
</style>
