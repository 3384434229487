<template>
	<p-dialog
		v-model:visible="show_verification_modal"
		class="verify-payment-method-modal"
		:modal="true"
		style="max-width: 720px"
	>
		<loader :show="loading" />
		<template #header>
			<div class="flex gap-2 align-content-center align-items-center">
				<icon type="license" size="24px" style="color: var(--color-b)" />
				<strong v-if="viewMode">Verification Docs For Card: {{ name_on_card }} - {{ last_four }}</strong>
        <strong v-else>Verify Payment Method: {{ name_on_card }} - {{ last_four }}</strong>
			</div>
		</template>
		<div>
      <div v-if="viewMode">
        <p>These are the files that were used to verify the payment method</p>
        <p>The payment method has <strong>{{ verified ? 'been verified' : 'NOT been verified'}}</strong></p>
        <p-message v-if="files.length === 0" :closable="false">There are no verification documents uploaded</p-message>
        <div v-for="file in files" class="verification-holder">
          <img class="verification-doc" :src="file.url" />
        </div>
      </div>
			<div v-else-if="pendingVerification">
				<p>
					Use the following documents to verify the document, if there's inconsistencies, do <strong>NOT</strong> Verify
					and reach out to the buyer.
				</p>
				<div>
					<p-fieldset
						legend="Documents Checked"
						:pt="{
							legendTitle: { class: 'req' },
						}"
					>
						<div class="control-group">
							<div class="inner">
								<div class="controls">
									<div class="field">
										<div class="flex flex-wrap gap-3 align-items-center">
											<div class="flex align-items-center">
												<p-checkbox v-model="documents_checked" input-id="dl" name="dl" value="drivers_license" />
												<label for="dl" class="ml-2 clickable">Drivers License</label>
											</div>
											<div class="flex align-items-center m-2">
												<p-checkbox v-model="documents_checked" input-id="id" name="id" value="identification" />
												<label for="id" class="ml-2 clickable">Identification</label>
											</div>
											<div class="flex align-items-center m-2">
												<p-checkbox v-model="documents_checked" input-id="card" name="card" value="card" />
												<label for="card" class="ml-2 clickable">Payment Card</label>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div v-if="v$.documents_checked.$error">
								<p v-for="error in v$.documents_checked.$errors" :key="error.$uid" class="validation-error">
									{{ error.$message }}
								</p>
							</div>
						</div>
					</p-fieldset>

					<gutter size="25px" />
					<h4>Documents Uploaded:</h4>
				</div>
				<div v-for="file in files" class="verification-holder">
					<img class="verification-doc" :src="file.url" />
				</div>
			</div>
			<div v-else>
				<div class="verify-content">
					<p>If the Buyer sent you the files directly, upload them here for this payment method.</p>
					<p>Make sure that names on the card match the ID or DL</p>
					<p><strong>See Example Below</strong></p>
					<p><img src="/assets/img/illustrations/id.svg" alt="Drivers License Illustration" /></p>
					<div class="file-upload">
						<image-upload v-model="url" :folder="folderName" mode="advanced" @success="handleUpload" />
					</div>
				</div>
			</div>

			<div v-if="v$.files.$error">
				<p v-for="error in v$.files.$errors" :key="error.$uid" class="validation-error">
					{{ error.$message }}
				</p>
			</div>
		</div>
		<template #footer>
			<div class="flex justify-content-end">
				<p-button text label="Cancel" @click="show_verification_modal = false" />
				<p-button icon="pi pi-check" v-if="!viewMode" label="Submit Verification" @click="submitVerification" />
			</div>
		</template>
	</p-dialog>
</template>

<script lang="ts">
import { useVuelidate } from '@vuelidate/core';
import { helpers, minLength, required } from '@vuelidate/validators';
import pButton from 'primevue/button';
import pDialog from 'primevue/dialog';
import { getFiles, verifyPaymentMethod } from '@GQL';
import pMessage from 'primevue/message';
import ImageUpload from '@/components/forms/ImageUpload.vue';
import Gutter from '@/components/elements/Gutter.vue';

export default {
	name: 'VerifyPaymentMethodModal',
	components: {
		Gutter,
		pButton,
		pDialog,
		ImageUpload,
		pMessage,
	},
	setup() {
		return {
			v$: useVuelidate(),
		};
	},
	emits: ['refresh'],
	computed: {
		accountId() {
			return (
				this.$route.params.account_id || this.$route.params.parent_account_id || this.$root.sessionStore.account.id
			);
		},
    viewMode() {
      // used when the verification is already complete
      return this.verified;
    },
		pendingVerification() {
			return this.files.length > 0;
		},
		folderName() {
			return `payment-methods/${this.payment_method_id}`;
		},
		folderPrefix() {
			let folder_prefix = '';
			if (!this.$root.sessionStore.isAdminUser) {
				folder_prefix += this.$root.appStore.mpid + '/';
			} else if (this.$route.params.mpid) {
				folder_prefix += this.$route.params.mpid + '/';
			}

			folder_prefix += this.accountId + '/';

			return folder_prefix;
		},
	},
	data() {
		return {
			loading: false,
			show_verification_modal: false,
			documents_checked: [],
			files: [],
			existing_upload: false,
			url: '',
			last_four: '',
			name_on_card: '',
			payment_method_id: '',
      verified: false
		};
	},
	methods: {
		async openModal(card) {
      console.log('cccccccccc', card);
			this.loading = true;
			try {
				this.payment_method_id = card.id;
				this.name_on_card = card.name_on_card;
				this.last_four = card.last_four;
        this.verified = card.verified;

				await this.getVerificationFiles();

				this.show_verification_modal = true;
			} catch (err) {
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to get files',
					life: 6000,
				});
			} finally {
				this.loading = false;
			}
		},
		handleUpload(upload_data) {
			// here we set the URL, show the image, then we can proceed with the verification
			this.files = upload_data;
		},
		async getVerificationFiles() {
			const full_path = (this.folderPrefix + this.folderName).replace(/\/\//, '/');
			const results = await getFiles(full_path);
			this.folders = results.folders;
			this.files = results.files;
		},
		async submitVerification() {
			const valid = await this.v$.$validate();
			if (valid) {
				this.loading = true;
				try {
					// we do an update to the payment methods
					const result = await verifyPaymentMethod(this.payment_method_id, {
						account_id: this.accountId,
						documents_checked: this.documents_checked,
					});
					if (result.id) {
						this.$toast.add({
							severity: 'success',
							summary: 'Payment method was verified',
							life: 5000,
						});
						this.show_verification_modal = false;
						this.$emit('refresh');
					}
				} catch (err) {
					this.$toast.add({
						severity: 'error',
						summary: 'Unable to verify, files may be missing',
						life: 6000,
					});
				} finally {
					this.loading = false;
				}
			} else {
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to verify, files may be missing',
					life: 6000,
				});
			}
		},
	},
	validations() {
		return {
			files: {
				min_length: helpers.withMessage('At least one file must be submitted as proof', minLength(1)),
			},
			documents_checked: {
				required: helpers.withMessage('Please check the documents verified', required),
				min_length: helpers.withMessage('At least one document should have been checked', minLength(1)),
			},
		};
	},
};
</script>

<style lang="less" scoped>
.file-upload {
	margin: 1rem auto;
	text-align: center;
}

.verify-content {
	margin: 0 auto;
	max-width: 600px;
	text-align: left;

	img {
		max-width: 350px;
	}
}

.verification-holder {
	margin-bottom: 30px;
	.verification-doc {
		max-width: 600px;
	}
}

.action {
	color: red;
	cursor: pointer;

	&:hover {
		color: var(--color-b);
	}
}

.clickable:hover {
	cursor: pointer;
}
</style>
