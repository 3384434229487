<template>
	<div id="live-transfer-start" class="view-content">
		<div class="page-heading">
			<h1>Live Transfer Leaderboards</h1>
		</div>
		<gutter size="20px" />
		<row class="justify-content-start">
			<div class="card padded">
				<h3>Today's Leaderboard</h3>

				<p-data-table ref="dt" :loading="loading" scrollable :value="leaderboard.today">
					<template #empty>
						<div class="dim">No results</div>
					</template>
					<template #loading>
						<line-loader :show="loading" />
					</template>
					<p-column header="Rank" class="align-center">
						<template #body="row">
							{{ row.data.rank }}
						</template>
					</p-column>
					<p-column header="Agent Name" class="s">
						<template #body="row">
							{{ row.data.agent_name }}
						</template>
					</p-column>

					<p-column header="Last Transfer" class="s">
						<template #body="row">
							{{ timeAgo(row.data.last_transfer) }}
						</template>
					</p-column>

					<p-column header="Completed" class="align-center">
						<template #body="row">
							{{ row.data.completed_transfers }}
						</template>
					</p-column>
				</p-data-table>
			</div>

			<gutter size="30px" />

			<div class="card padded">
				<h3>Week's Leaderboard</h3>

				<p-data-table ref="dt" :loading="loading" scrollable :value="leaderboard.week">
					<template #empty>
						<div class="dim">No results</div>
					</template>
					<template #loading>
						<line-loader :show="loading" />
					</template>

					<p-column header="Rank" class="align-center">
						<template #body="row">
							{{ row.data.rank }}
						</template>
					</p-column>
					<p-column header="Agent Name" class="s">
						<template #body="row">
							{{ row.data.agent_name }}
						</template>
					</p-column>

					<p-column header="Completed" class="align-center">
						<template #body="row">
							{{ row.data.completed_transfers }}
						</template>
					</p-column>
				</p-data-table>
			</div>
			<gutter size="30px" />

			<div class="card padded">
				<h3>Month's Leaderboard</h3>
				<p-data-table ref="dt" :loading="loading" scrollable :value="leaderboard.month">
					<template #empty>
						<div class="dim">No results</div>
					</template>

					<template #loading>
						<line-loader :show="loading" />
					</template>

					<p-column header="Rank" class="align-center">
						<template #body="row">
							{{ row.data.rank }}
						</template>
					</p-column>
					<p-column header="Agent Name" class="s">
						<template #body="row">
							{{ row.data.agent_name }}
						</template>
					</p-column>

					<p-column header="Completed" class="align-center">
						<template #body="row">
							{{ row.data.completed_transfers }}
						</template>
					</p-column>
				</p-data-table>
			</div>
		</row>
	</div>
</template>

<script>
import { reportLiveTransferLeaderboard } from '@GQL';
import { startOfDay, startOfWeek, startOfMonth } from 'date-fns';
import { formatDate, timeAgo } from '@/lib/Filters';

export default {
	name: 'LiveTransferLeaderboard',
	props: {},
	data() {
		return {
			loading: false,
			leaderboard: {
				today: [],
				week: [],
				month: [],
			},
		};
	},
	methods: {
		timeAgo,
		formatDate,
		async getLeaderboard(date_range, timeframe) {
			this.loading = true;
			try {
				const res = await reportLiveTransferLeaderboard({ date_range });

				this.leaderboard[timeframe] = res.rows.map((row, index) => {
					return { ...row, rank: index + 1 };
				});
			} catch (err) {
				this.$toast.add({
					severity: 'error',
					summary: `Unable to load ${timeframe}'s leaderboard data`,
					life: 6000,
				});
			} finally {
				this.loading = false;
			}
		},
	},
	async mounted() {
		const today = [startOfDay(new Date()).toISOString(), new Date().toISOString()];
		const week = [startOfWeek(new Date()).toISOString(), new Date().toISOString()];
		const month = [startOfMonth(new Date()).toISOString(), new Date().toISOString()];

		this.getLeaderboard(today, 'today').catch((err) => {});
		this.getLeaderboard(week, 'week').catch((err) => {});
		this.getLeaderboard(month, 'month').catch((err) => {});
	},
};
</script>

<style lang="less" scoped></style>
