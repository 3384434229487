<template>
	<p-dialog v-model:visible="show_modal" class="" :modal="true" style="max-width: 720px">
		<template #header>
			<div class="flex gap-2 align-content-center align-items-center">
				<icon type="credit-card-plus" size="24px" style="color: var(--color-b)" />
				<strong>Skip</strong>
			</div>
		</template>
		<div>
			<p>Select Reason for skipping</p>

			<div class="flex-flex-column">
				<div class="control-group">
					<div class="inner">
						<label class="control-label req">Skip Reason:</label>
						<div class="controls">
							<div class="field">
								<p-dropdown v-model="reason" :options="options" option-label="label" option-value="value" />
							</div>
						</div>
					</div>
					<p class="validation-error" v-if="show_error">A reason is required to skip this account</p>
				</div>

				<div class="control-group">
					<div class="inner">
						<label class="control-label req">Note:</label>
						<div class="controls">
							<div class="field">
								<p-textarea v-model="notes" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<template #footer>
			<div class="flex justify-content-end">
				<p-button label="Cancel" text @click="show_modal = false" />
				<p-button label="Submit" @click="confirm" />
			</div>
		</template>
	</p-dialog>
</template>

<script>
export default {
	emits: ['skip'],
	data() {
		return {
			options: [
				{
					label: 'Lead does not want quote from company or was already quoted by company',
					value: 'Already has quote from company',
				},
				{
					label: 'Lead is currently insured by company and they want to switch / compare rates',
					value: 'Currently insured by company',
				},
				{ label: 'Other (please enter notes below)', value: 'Other' },
			],
			reason: '',
			notes: '',
			show_error: false,
			show_modal: false,
		};
	},
	watch: {
		reason(new_val) {
			if (new_val !== '') {
				this.show_error = false;
			}
		},
	},
	methods: {
		open() {
			this.show_error = false;
			this.reason = '';
			this.notes = '';
			this.show_modal = true;
		},
		confirm() {
			if (this.reason === '') {
				this.show_error = true;
				this.$toast.add({
					severity: 'error',
					summary: 'A reason is needed to skip this account',
					life: 6000,
				});
			} else {
				// validate...
				this.$emit('skip', {
					reason: this.reason,
					notes: this.notes,
				});
				this.show_modal = false;
			}
		},
	},
};
</script>
